html, body, #root {
  height: 100%;
  /* min-height: 100%; */
}

.buttons {
  margin: 1em 0;
}

.buttons button {
  margin: 0;
  margin-right: 0.5em;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;

  background-color: #eee;
}

.content {
  background-color: white;
  margin-top: 2em;
  border-radius: 1em;
  padding: 1em;
  margin: 1em;
}

.intro {
  /* text-align: center; */
}

.section-label {
  font-weight: bold;
  margin-top: 1em;
}

.hl1 {
  background: black;
  color: white;
  font-family: monospace;
  padding: 2em 5em;
  margin: 2em 0;
}

.form-group {
  margin-bottom: 1em;
}